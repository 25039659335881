import React, { FC } from "react"

import * as R from "ramda"

import { graphql, Link, PageProps } from "gatsby"

import Layout from "../../components/layout"
import { RemarkQueryType } from "../../types"

export const query = graphql`
  query {
    blogposts: allMarkdownRemark(
      filter: { fields: { legacySlug: { ne: null }, sourceName: { eq: "blogposts" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
            legacySlug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`

type QueryProps = {
  data: {
    blogposts: RemarkQueryType<
      {
        title: string
      },
      {
        slug: string
        legacySlug: string
      }
    >
  }
}

type Props = PageProps & QueryProps

const DebugRedirectsPage: FC<Props> = ({ data }) => (
  <Layout>
    <h2>Debug: Redirects</h2>
    <h3>Table</h3>
    <p>This page lists all redirects for blogposts that are present in the website</p>

    <table>
      <thead>
        <tr>
          <th>From</th>
          <th>To</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.blogposts &&
          data.blogposts.edges
            .map((edge) => edge.node)
            .map((post) => (
              <React.Fragment key={post.id}>
                <tr>
                  <td>
                    <Link to={post.fields.legacySlug}>{post.fields.legacySlug}</Link>
                  </td>
                  <td>
                    <Link to={post.fields.slug}>{post.fields.slug}</Link>
                  </td>
                </tr>
              </React.Fragment>
            ))}
      </tbody>
    </table>

    <hr />

    <h3>Nginx redirect map config</h3>
    <p>A map file for nginx redirects</p>

    <NginxMapConfig data={data} />
  </Layout>
)

export default DebugRedirectsPage

const removeTrailingSlash = (string: string) => {
  if (string.substr(string.length - 1, string.length) === "/") {
    return string.substr(0, string.length - 1)
  } else {
    return string
  }
}

const NginxMapConfig: FC<QueryProps> = ({ data }) => {
  if (data && data.blogposts) {
    const posts = data.blogposts.edges.map((edge) => edge.node)

    const maxLength = R.reduce(
      R.max,
      0,
      posts.map((post) => post.fields.legacySlug.length)
    )

    console.log("max:" + maxLength)

    const lines = posts.map((post) => {
      const target = removeTrailingSlash(post.fields.slug)
      const legacy = post.fields.legacySlug

      const widthSlash = `${(legacy + "/").padEnd(maxLength, " ")}  ${target};`
      const widthoutSlash = `${legacy.padEnd(maxLength, " ")}  ${target};`

      return `  ${widthSlash}\n  ${widthoutSlash}\n`
    })

    const rules = R.join("")(lines)

    return (
      <code>
        <pre style={{ background: "#eee", paddingLeft: "1em" }}>
          {`
map_hash_bucket_size 128;
map $request_uri $redirect_uri {
${rules}
}
        `}
        </pre>
      </code>
    )
  }
}
